.App {
  text-align: center;
  scroll-behavior: smooth;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* css for responsive standard premium text */
.responsive-heading {
  font-weight: 100;
  margin: 0;
  font-size: 2rem;
  text-align: center;
}

@media (max-width: 200px) {
  .responsive-heading {
    font-size: 0.25rem;
  }
}

@media (max-width: 300px) {
  .responsive-heading {
    font-size: 0.40rem;
  }
}
@media (max-width: 380px) {
  .responsive-heading {
    font-size: 0.50rem;
  }
}

@media (max-width: 400px) {
  .responsive-heading {
    font-size: 0.55rem;
  }
}
@media (max-width: 450px) {
  .responsive-heading {
    font-size: 0.60rem;
  }
}

@media (max-width: 500px) {
  .responsive-heading {
    font-size: 0.70rem;
  }
}
@media (max-width: 600px) {
  .responsive-heading {
    font-size: 1rem;
  }
}

@media (max-width: 620px) {
  .responsive-heading {
    font-size: 1.10rem;
  }
}
@media (max-width: 640px) {
  .responsive-heading {
    font-size: 1.20rem;
  }
}
@media (max-width: 660px) {
  .responsive-heading {
    font-size: 1.30rem;
  }
}
@media (max-width: 680px) {
  .responsive-heading {
    font-size: 1.5rem;
  }
}

.footer-button {
  color: #B3FF11;
}

@media (max-width: 600px) {
  .footer-button {
    font-size: 12px; /* Smaller font size for mobile */
  }
}


